<template>
  <div class="roleTab clearfix">
    <div class="apply-table">
      <global-table ref="libraryData" :tableField="tableField" :tableData="tableData"
    ></global-table>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
// import { request } from '@/assets/js/http.js'
import { tableField, tableData } from './RevokeBody.js'
export default {
  components: Component.components,
  name: 'RevokeBody',
  props: ['tabData', 'selectTabData'],
  data () {
    return {
      desc: '',
      tableField: tableField,
      tableData: tableData
    }
  },
  created () {
    this.tableData = this.selectTabData
  },
  mounted () {

  },
  methods: {

  },
  watch: {
  }
}
</script>
<style scoped lang="scss">
  .tableNav{
    border: none;
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
  .apply-table .el-textarea{
    padding: 20px 20px 0;
    width: calc(100% - 40px);
  }
</style>
