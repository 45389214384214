export var tableField = [
  { label: '需求单号', code: 'demandCode', type: 'input', width: '35%' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '25%' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '' },
  { label: '需求数量/单位', code: 'demandNumber', type: 'input', width: '30%' },
  { label: '期望送货日期', code: 'demandTime', type: 'input', width: '25%' },
  { label: '需求送货基地', code: 'baseName', type: 'input', width: '25%' }
]

export var tableData = [
]
